<script>
import {listConta as list, deleteConta} from '@/domain/financeiro/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import MenuOptions from '../../layout/context-menu/context-window-options'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import listOptions from '../windows/listOptions'
import windowConta from '../windows/conta'
import {datePtToEn} from '@/utils/date'
import tableColumnsCache from "@/utils/tableColumnsCache"
import ERow from "@/components/layout/components/Row"
import Resumo from "./lista/Resumo"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import ListMenu from "@/components/financeiro/components/lista/list-menu"
import {differenceInHours, parseISO, format, addDays, subDays, startOfWeek, endOfWeek, addMonths, subMonths, startOfMonth, endOfMonth, addWeeks, subWeeks} from "date-fns"
import FinSaldoContas from "@/components/financeiro/components/lista/SaldoContas"
import FiltroConta from "@/components/financeiro/components/input/FiltroConta"
import FiltroPeriodo from "@/components/financeiro/components/input/FiltroPeriodo"
import FinFiltrosAdicionais from "@/components/financeiro/components/input/FiltrosAdicionais"
import {donwloadFile} from "@/utils/downloadFile"
import EBtn from "@/plugins/uloc-erp/components/button/EBtn"
import filters from "@/domain/financeiro/helpers/filters"


const listName = 'sl.fin-conta.lista'
const listStorage = tableColumnsCache(listName, [
  {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
  {label: 'Nro Doc', name: 'numeroDoc', active: false, sortable: true, ordering: 1},
  {label: 'Data', name: 'dataVencimento', active: true, sortable: true, ordering: 1},
  {label: 'Pagamento', name: 'dataPagamento', active: false, sortable: true, ordering: 1},
  {label: 'Descrição', name: 'descricao', active: true, sortable: true, ordering: 1},
  {label: 'Pessoa', name: 'pessoa', active: true, sortable: true, ordering: 1},
  {label: 'Lançamento', name: 'categoriaSimples', active: true, sortable: true, ordering: 1},
  {label: 'Lançamento Detalhado', name: 'categoria', active: false, sortable: true, ordering: 1},
  {label: 'Pagamento', name: 'dataPagamento', active: false, sortable: true, ordering: 1},
  {label: 'Conta', name: 'conta', active: false, sortable: true, ordering: 1},
  {label: 'Centro de Custo', name: 'centroCusto', active: false, sortable: true, ordering: 1},
  {label: 'Data Registro', name: 'dataRegistro', active: false, sortable: true, ordering: 1},
  {label: 'Forma de Pagamento', name: 'formaPagamento', active: true, sortable: true, ordering: 1},
  {label: 'Dedutível', name: 'dedutivel', active: false, sortable: true, ordering: 1},
  {label: 'Valor', name: 'valor', active: true, sortable: true, ordering: 1},
  {label: 'Valor Pago', name: 'valorPago', active: false, sortable: true, ordering: 1},
  {label: 'Situação', name: 'status', active: true, sortable: true, ordering: 1},
], 12)

export default {
  name: 'Contas',
  provide: function () {
    return {
      container: this
    }
  },
  props: {
    pessoa: {required: false},
    noDateFilter: {required: false, type: Boolean, default: false},
    ocultarResumo: {required: false, type: Boolean, default: false},
    parseProps: {required: false},
    hideTabs: {
      type: Boolean,
      default: false
    }
  },
  mixins: [DefaultMixin],
  components: {
    EBtn,
    ErpSelect,
    FinFiltrosAdicionais,
    FiltroPeriodo,
    FiltroConta,
    FinSaldoContas,
    ListMenu,
    DateInput,
    ECol,
    Resumo,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    // ErpSelect,
    ErpSField,
    // ErpSelect,
    // UPopover,
    // UTooltip
  },
  data() {
    const filtroPeriodos = [
      {
        id: 'vencido',
        value: 'Vencido', active: false, action: function () {
          //this.table.filters.data1 = null
          //this.table.filters.data2 = null
          this.table.filters.dueFilter = true
        }
      },
      {
        id: 'hoje', type: 'day',
        value: 'Hoje', active: false, action: function () {
          this.table.filters.data1 = format(new Date(), 'dd/MM/yyyy')
          this.table.filters.data2 = format(new Date(), 'dd/MM/yyyy')
        }
      },
      {
        id: 'amanha', type: 'day',
        value: 'Amanhã', active: false, action: function () {
          this.table.filters.data1 = format(addDays(new Date(), 1), 'dd/MM/yyyy')
          this.table.filters.data2 = format(addDays(new Date(), 1), 'dd/MM/yyyy')
        }
      },
      {
        id: 'esta-semana', type: 'week',
        value: 'Esta semana', active: false, action: function () {
          const start = addDays(startOfWeek(new Date()), 0);
          const end = subDays(endOfWeek(new Date()), 0);
          this.table.filters.data1 = format(start, 'dd/MM/yyyy')
          this.table.filters.data2 = format(end, 'dd/MM/yyyy')
        }
      },
      {
        id: 'proxima-semana', type: 'week',
        value: 'Próxima semana', active: false, action: function () {
          const end = endOfWeek(new Date());
          this.table.filters.data1 = format(addDays(startOfWeek(addDays(end, 2)), 0), 'dd/MM/yyyy')
          this.table.filters.data2 = format(subDays(endOfWeek(addDays(end, 2)), 0), 'dd/MM/yyyy')
        }
      },
      {
        id: 'este-mes', type: 'month',
        value: 'Este mês', active: true, action: function () {
          const end = endOfMonth(new Date());
          this.table.filters.data1 = format(startOfMonth(new Date()), 'dd/MM/yyyy')
          this.table.filters.data2 = format(end, 'dd/MM/yyyy')
        }
      },
      {
        id: 'proximo-mes', type: 'month',
        value: 'Próximo mês', active: false, action: function () {
          let date = new Date()
          date = addMonths(date, 1)
          const end = endOfMonth(date);
          this.table.filters.data1 = format(startOfMonth(date), 'dd/MM/yyyy')
          this.table.filters.data2 = format(end, 'dd/MM/yyyy')
        }
      }
    ]

    const filtros = JSON.parse(JSON.stringify(filters))
    if (!this.noDateFilter) {
      const end = endOfMonth(new Date());
      filtros.data1 = format(startOfMonth(new Date()), 'dd/MM/yyyy')
      filtros.data2 = format(end, 'dd/MM/yyyy')
    }
    return {
      listStorage: listStorage,
      listType: 'e',
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 50
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      },
      filtroPeriodos: filtroPeriodos,
      stats: null,
      counts: null,
      hideMoneyInfo: false
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    isEmbedComponent () {
      return this.parseProps && this.parseProps.embedComponent
    }
  },
  beforeMount () {
    if (this.$route.hash) {
      this.parseHash()
    }
  },
  watch: {
    '$route' () {
      this.$nextTick(() => {
        if (this.parseHash()) {
          this.load()
        }
      })
    },
    'table.filters.tipoData' () {
      this.$nextTick(() => {
        this.pesquisar()
      })
    }
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    parseHash () {
      if (this.$route.hash) {
        const hash = this.$route.hash.replace('#', '')
        if (hash === 'pagar') {
          this.listType = 'd'
          this.table.filters.tipo = 2
          //this.load()
        }
        if (hash === 'receber') {
          this.table.filters.tipo = 1
          this.listType = 'r'
          //this.load()
        }
        if (hash === 'extrato') {
          this.table.filters.tipo = null
          this.listType = 'e'
          //this.load()
        }
        return true
      }
    },
    request({pagination, filter}, exportar = null) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)
      this.table.filters.search && extraFilters.push(`&search=${this.table.filters.search}`)

      if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }

      if (Array.isArray(this.table.filters.conta)) {
        extraFilters.push(`&conta=${this.table.filters.conta.join(',')}`)
      }

      this.relacaoEntidade && extraFilters.push(`&entity=${this.relacaoEntidade}`)
      this.relacaoEntidadeId && extraFilters.push(`&entityId=${this.relacaoEntidadeId}`)

      this.table.filters.onlyOwnerTasks && extraFilters.push(`&onlyOwnerTasks=${this.table.filters.onlyOwnerTasks}`)
      this.table.filters.participants && extraFilters.push(`&participants=${this.table.filters.participants}`)
      this.table.filters.participants && extraFilters.push(`&participantFilter=${this.table.filters.participantFilter}`)

      this.table.filters.dueFilter && extraFilters.push(`&dueFilter=${this.table.filters.dueFilter}`)
      this.table.filters.centroCusto && extraFilters.push(`&centroCusto=${this.table.filters.centroCusto.id}`)
      this.table.filters.relationEntityId && extraFilters.push(`&relationEntityId=${this.table.filters.relationEntityId}&relationEntity=${this.table.filters.relationEntity}`) // @TODO: Isso é usado?

      this.table.filters.pessoa && extraFilters.push(`&pessoa=${this.table.filters.pessoa.id || this.table.filters.pessoa}`)
      this.table.filters.categoria && extraFilters.push(`&categoria=${this.table.filters.categoria}`)
      this.table.filters.processo && extraFilters.push(`&processo=${this.table.filters.processo.id}`)

      if (this.parseProps) {
        console.log(this.parseProps)
        if (this.parseProps.module === 'processos') {
          this.parseProps.processo && extraFilters.push(`&processo=${this.parseProps.processo.id}`)
        } else {
          this.parseProps && this.parseProps.relationEntity && extraFilters.push(`&relationEntity=${this.parseProps.relationEntity}`)
          this.parseProps && this.parseProps.relationEntityId && extraFilters.push(`&relationEntityId=${this.parseProps.relationEntityId}`)
          this.parseProps && this.parseProps.relationEntityId && extraFilters.push(`&relationEntityId=${this.parseProps.relationEntityId}`)
        }
      }

      if (this.table.filters.dedutivel === true || this.table.filters.dedutivel === false) {
        extraFilters.push(`&dedutivel=${this.table.filters.dedutivel ? 1 : 0}`)
      }

      if (this.table.filters.pago === true || this.table.filters.pago === false) {
        extraFilters.push(`&pago=${this.table.filters.pago ? 1 : 0}`)
      }

      this.pessoa && extraFilters.push(`&pessoa=${this.pessoa.id}`)

      if (exportar) {
        extraFilters.push(`&export=${exportar}`)
      }

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dataVencimento'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, exportar)
          .then((response) => {
            if (exportar) {
              donwloadFile(response)
              this.table.loading = false
              return
            }
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = response.data.total
            this.table.serverData = response.data.result
            this.stats = response.data.stats
            if (response.data.counts) {
              this.counts = response.data.counts
            }

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
            this.table.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters() {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo(t = null) {
      console.log('New...')
      let relacaoEntidade = null
      let relacaoEntidadeId = null
      let pessoa = null
      let extraRelations = null
      let processo = null
      let tags = null
      if (this.parseProps && this.parseProps.relationEntity) {
        relacaoEntidade = this.parseProps.relationEntity
        relacaoEntidadeId = this.parseProps.relationEntityId
      }
      if (this.parseProps && this.parseProps.processo) {
        processo = this.parseProps.processo
      }
      if (this.pessoa) {
        pessoa = this.pessoa
      }
      this.windowConta(null, t || this.listType, relacaoEntidade, relacaoEntidadeId, pessoa, extraRelations, tags, processo)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      // this.$router.push({name: 'sl.tarefas.show', params: {id: id}})
      this.windowConta(id, this.listType)
    },
    edit(id) {
      this.windowConta(id, this.listType)
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir conta-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteConta(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Conta excluída com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(listName, newConfig)
      this.listStorage = newConfig
    },
    changeListType (v) {
      this.listType = v
      if (v === 'e') {
        this.table.filters.tipo = null
      } else if (v === 'r') {
        this.table.filters.tipo = 1
      } else if (v === 'd') {
        this.table.filters.tipo = 2
      } else {}
      const end = endOfMonth(new Date());
      /* this.table.filters.data1 = format(startOfMonth(new Date()), 'dd/MM/yyyy')
      this.table.filters.data2 = format(end, 'dd/MM/yyyy')
      this.table.filters.dueFilter = false
      this.table.filters.tipoData = 'vencimento'
      this.filtroPeriodos.map(l => l.active = false)
      this.filtroPeriodos.map(l => {
        if (l.type === 'month') { l.active = true }
      }) */
      this.load()
    },
    windowConta: windowConta,
    buscaPorPeriodo (periodo) {
      this.table.filters.data1 = null
      this.table.filters.data2 = null
      this.table.filters.dueFilter = false
      this.$nextTick(() => {
        this.filtroPeriodos.map(l => periodo === l ? l.active = true : l.active = false)
        if (periodo.action) {
          periodo.action.call(this)
        }
      })
      this.$nextTick(() => {
        this.load()
      })
    },
    addsubPeriodo (periodo) {
      const p = this.filtroPeriodos.find(l => l.active)
      if (!p) return
      const type = p.type
      if (!['day', 'week', 'month'].includes(p.type)) {
        this.$uloc.notify({
          message: 'Selecione um intervalo de data para poder avançar/diminuir'
        })
        return;
      }
      if (periodo === 'add') {
        console.log('Adicionando 1 ' + type)
        if (type === 'day') {
          this.table.filters.data1 = format(addDays(parseISO(datePtToEn(this.table.filters.data1)), 1), 'dd/MM/yyyy')
          this.table.filters.data2 = format(addDays(parseISO(datePtToEn(this.table.filters.data2)), 1), 'dd/MM/yyyy')
        }
        if (type === 'week') {
          let end = endOfWeek(parseISO(datePtToEn(this.table.filters.data1)))
          let start = addDays(end, 1)
          end = endOfWeek(start)
          this.table.filters.data1 = format(start, 'dd/MM/yyyy')
          this.table.filters.data2 = format(end, 'dd/MM/yyyy')
        }
        if (type === 'month') {
          let end = endOfMonth(parseISO(datePtToEn(this.table.filters.data1)))
          let start = addDays(end, 1)
          end = endOfMonth(start)
          this.table.filters.data1 = format(start, 'dd/MM/yyyy')
          this.table.filters.data2 = format(end, 'dd/MM/yyyy')
        }
      } else {
        // sub
        console.log('Subtraindo 1 ' + type)
        if (type === 'day') {
          this.table.filters.data1 = format(subDays(parseISO(datePtToEn(this.table.filters.data1)), 1), 'dd/MM/yyyy')
          this.table.filters.data2 = format(subDays(parseISO(datePtToEn(this.table.filters.data2)), 1), 'dd/MM/yyyy')
        }
        if (type === 'week') {
          let end = subDays(parseISO(datePtToEn(this.table.filters.data1)), 1)
          let start = startOfWeek(end)
          this.table.filters.data1 = format(start, 'dd/MM/yyyy')
          this.table.filters.data2 = format(end, 'dd/MM/yyyy')
        }
        if (type === 'month') {
          let end = subDays(parseISO(datePtToEn(this.table.filters.data1)), 1)
          let start = startOfMonth(end)
          this.table.filters.data1 = format(start, 'dd/MM/yyyy')
          this.table.filters.data2 = format(end, 'dd/MM/yyyy')
        }
      }
      this.load()
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    label (a, b) {
      /*if (this.listType === b) {
        return a + ` (${this.table.serverPagination.rowsNumber})`
      }*/
      return a
    },
    convert (d) {
      return datePtToEn(d)
    },
    filtrarBancos (bancos) {
      this.table.filters.conta = bancos
      this.load()
    },
    exportar (tipo = 'excel') {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      }, tipo)
    },
  },
  meta: {
    title: 'Financeiro',
    name: 'Financeiro'
  }
}
</script>

<template>
    <div class="fin-container wrapper-md" :style="isEmbedComponent ? 'padding: 0' : ''">
      <e-row class="m-b-lg">
        <e-col v-if="!ocultarResumo" style="max-width: 350px">
          <resumo v-if="!isEmbedComponent" :hide-money-info="hideMoneyInfo" @hide="hideMoneyInfo = !hideMoneyInfo" :stats="stats" :isEmbedComponent="isEmbedComponent" /> <!-- @TODO: Fazer isto funcionar de acordo o filtro de relacao -->
        </e-col>
<!--        <e-col class="flex items-start justify-end col-grow-1">
          <div class="saldo-contas font-12 font-avenir text-grey-7 flex column m-l-xxl">
            <div class="at-title m-b">PERÍODO</div>
            <ul class="menu-alfabeto bold flex column" style="margin-top: 0; margin-left: -8px">
              <li v-for="filtro in filtroPeriodos" :key="filtro.value"><a @click="buscaPorPeriodo(filtro)" :class="{active: filtro.active}">{{ filtro.name || filtro.value }}</a></li>
            </ul>
          </div>
        </e-col>-->
        <e-col :class="!ocultarResumo ? 'flex items-center justify-center col-grow-1' : 'flex items-center'">
<!--          <ul class="menu-alfabeto bold m-l-xxl">
            <li v-for="filtro in filtroPeriodos" :key="filtro.value"><a @click="buscaPorPeriodo(filtro)" :class="{active: filtro.active}">{{ filtro.name || filtro.value }}</a></li>
          </ul>-->
          <filtro-conta @change="filtrarBancos" :stats="stats" />
          <filtro-periodo :stats="stats" @mudarPeriodo="buscaPorPeriodo" @addsub="addsubPeriodo" :periodos="filtroPeriodos" :filters="table.filters" class="m-l-lg" />
        </e-col>
        <e-col class="justify-end flex" style="max-width: 200px" :style="isEmbedComponent ? 'text-align: right' : ''">
          <fin-saldo-contas @new="(t) => novo(t)" :hide-money-info="hideMoneyInfo" :type="listType" :stats="stats" :isEmbedComponent="isEmbedComponent" />
        </e-col>
      </e-row>
      <div class="erp-list list-tasks no-pad">
        <div class="erp-list-top-btns items-end">
          <div v-if="!hideTabs" class="actives-btns col-grow-1">
            <u-btn @click="changeListType('e')" :label="label('Extrato', 'e')" class="active-table-btn" :class="{active: listType === 'e'}" no-caps />
            <u-btn @click="changeListType('d')" :label="label('Contas a Pagar', 'd')" class="active-table-btn" :class="{active: listType === 'd'}" no-caps />
            <u-btn @click="changeListType('r')" :label="label('Contas a Receber', 'r')" class="active-table-btn" :class="{active: listType === 'r'}" no-caps />
          </div>
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table erp-table-options table-fin"
            :rows-per-page-options="[20, 50, 100, 500]"
            :hide-no-results-label="true"
        >
          <template slot="top" slot-scope="props">
            <div class="full-width">
            <e-row class="e-input-modern size1">
              <e-col style="min-width: 150px" class="m-l m-r">
                <erp-s-field
                    view="ll"
                    label="Buscar"
                >
                  <erp-input shortkey="F3" v-model="table.filters.search" @keydown.enter="pesquisar"/>
                </erp-s-field>
              </e-col>
              <e-col style="min-width: 150px" class="m-r">
                <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (De)" v-model="table.filters.data1"/>
              </e-col>
              <e-col style="min-width: 150px" class="m-r">
                <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (Até)" v-model="table.filters.data2"/>
              </e-col>
              <e-col style="min-width: 150px" class="m-r">
                <erp-select v-model="table.filters.tipoData" :options="[
                    {label: 'Vencimento', value: 'vencimento'},
                    {label: 'Pagamento', value: 'pagamento'},
                    {label: 'Competência', value: 'competencia'},
                ]" />
              </e-col>
              <e-col><e-btn @click="pesquisar" label="Filtrar" /></e-col>
              <e-col class="flex items-end content-end justify-end">
                <fin-filtros-adicionais :filters="table.filters" :stats="stats" />
              </e-col>
            </e-row>
            <e-row>
            </e-row>
            </div>
          </template>
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              <template v-if="col.name === 'pessoa'">
                <span v-if="listType === 'd'">Fornecedor</span>
                <span v-else-if="listType === 'r'">Credor</span>
                <span v-else>PESSOA</span>
              </template>
              <template v-else>
               {{ col.label }}
              </template>
              <div v-if="col.name === 'options'" class="text-center">
                <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
              </div>
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" :class="{'u-table-item-destac': props.row.isToday}">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs"/>
            </u-td>
            <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
              {{ props.row.id }}
            </u-td>
            <u-td style="max-width: 100px; min-width: 100px; width: 100px" key="numeroDoc" :props="props">
              {{ props.row.numeroDoc }}
            </u-td>
            <u-td class="td-limit" style="max-width: 150px; width: 150px" key="dataVencimento" :props="props">
              <div v-if="props.row.dataVencimento">
                <span :class="[{'line-through': props.row.cancelado}]">{{ props.row.dataVencimento|formatDate('dd/MM/yyyy') }}</span>
                <span class="m-l-xs trask-atraso-lbl" v-if="!props.row.cancelado && !props.row.pago && atraso(props.row.dataVencimento) > 0"><i class="fas fa-exclamation-triangle m-r-xs"></i> {{atraso(props.row.dataVencimento)}} dia{{atraso(props.row.dataVencimento) > 1 ? 's' : ''}}</span>
              </div>
              <span v-else>Sem previsão</span>
            </u-td>
            <u-td class="td-limit" style="max-width: 150px; width: 150px" key="dataPagamento" :props="props">
              <div v-if="props.row.dataPagamento">
                {{ props.row.dataPagamento|formatDate('dd/MM/yyyy') }}
              </div>
              <span v-else>-</span>
            </u-td>
            <u-td :title="props.row.descricao" class="td-limit l-130" key="descricao"
                  :props="props">
              <span v-if="props.row.parcelas > 1" class="font-thin m-r-xs">{{props.row.parcela}}/{{props.row.parcelas}}</span><span v-if="props.row.repetir" class="font-thin m-r-xs text-blue"><u-tooltip :offset="[4,4]">Lançamento recorrente</u-tooltip>R</span>{{ props.row.descricao }}
            </u-td>
            <u-td class="td-limit l-130 today" key="pessoa" :props="props">
              <div v-if="props.row.pessoa">
                {{props.row.pessoa.name}}
              </div>
              <span v-else>-</span>
            </u-td>
            <u-td class="td-limit" key="categoriaSimples" :props="props">
              <div v-if="props.row.rateio">
                <span class="u-chip font-10">Rateio</span>
              </div>
              <div v-else>
                <div v-if="props.row.categoria">{{props.row.categoria.descricao}}</div>
                <div v-else>-</div>
              </div>
            </u-td>
            <u-td class="td-limit" key="categoria" :props="props">
              <div v-if="props.row.rateio">
                <span class="u-chip">Rateio</span>
              </div>
              <div v-else>
                <div v-if="props.row.categoria && props.row.categoria.parent && props.row.categoria.parent.parent">
                  <span class="font-thin">{{props.row.categoria.parent.parent.descricao}} / {{props.row.categoria.parent.descricao}} /</span> {{props.row.categoria.descricao}}
                </div>
                <div v-else-if="props.row.categoria && props.row.categoria.parent">
                  <span class="font-thin">{{props.row.categoria.parent.descricao}} /</span> {{props.row.categoria.descricao}}
                </div>
                <div v-else-if="props.row.categoria">{{props.row.categoria.descricao}}</div>
                <div v-else>-</div>
              </div>
            </u-td>
            <u-td class="td-limit" key="dataPagamento" :props="props">
              <div v-if="props.row.dataPagamento">
                {{ props.row.dataPagamento.date|formatDate('dd/MM/yyyy') }}
              </div>
              <div v-else>-</div>
            </u-td>
            <u-td class="td-limit today" key="conta" :props="props">
              <div v-if="props.row.conta">
                {{props.row.conta.nome}}
              </div>
              <span v-else>-</span>
            </u-td>
            <u-td class="td-limit today" key="centroCusto" :props="props">
              <div v-if="props.row.centroCusto">
                {{props.row.centroCusto.nome}}
              </div>
              <span v-else>-</span>
            </u-td>
            <u-td class="td-limit" key="dataRegistro" :props="props">
              <div v-if="props.row.createdAt">
                {{ props.row.createdAt.date|formatDate('dd/MM/yyyy HH:mm') }}
              </div>
              <div v-else>Inexistente</div>
            </u-td>
            <u-td class="td-limit" key="formaPagamento" :props="props">
              <div v-if="props.row.formaPagamento">
                {{ props.row.formaPagamento.nome }}
              </div>
              <div v-else>-</div>
            </u-td>
            <u-td key="dedutivel" :props="props">
              <div v-if="props.row.dedutivel === true || props.row.dedutivel === false">
                <div v-if="props.row.dedutivel === true"><u-icon class="m-l-xs font-12 text-orange" name="check" type="fa" /></div><div v-else></div>
              </div>
              <div v-else></div>
            </u-td>
            <u-td class="td-limit no-wrap" :class="[{'line-through': props.row.cancelado}]" key="valor" :props="props">
              {{props.row.tipo == '1' ? '+' : '-'}} <strong :class="props.row.tipo == '1' ? 'positive-black' : 'negative-black'">R$ {{props.row.valor|moeda}}</strong>
            </u-td>
            <u-td class="td-limit no-wrap" key="valorPago" :props="props">
              <strong>R$ {{props.row.valorPago|moeda}}</strong>
            </u-td>
            <u-td class="" key="status" :props="props" style="max-width: 100px; width: 100px">
              <div class="text-negative font-bold" v-if="props.row.cancelado">
                <!--<span class="trask-atraso-lbl">Cancelado</span>-->
                <div v-if="props.row.pago">Estornado</div>
                <div v-else>Cancelado</div>
              </div>
              <div class="text-positive font-bold" v-else-if="props.row.pago">
                Pago <u-icon class="m-l-xs font-12" name="check" type="fa" />
              </div>
              <div v-else-if="atraso(props.row.dataVencimento) > 0" class="text-orange font-bold">Atrasado</div>
              <div v-else>Pendente</div>
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <list-menu
                      @abrir="abrir(props.row.id)"
                      @edit="edit(props.row.id)"
                      @excluir="excluir(props.row.id)"
                      :m="props.row"
                  />
                </menu-options>
              </e-btn-table-options>
            </u-td>
          </u-tr>
        </u-table>

        <div class="flex justify-between">
          <div class="m-t">
<!--            <u-btn icon="print" color="blue-grey-6" flat round no-caps>
              <u-tooltip :offset="[5,5]">Imprimir</u-tooltip>
            </u-btn>-->
            <u-btn @click="exportar('excel')" label="Exportar" color="blue-grey-6" class="app-btn font-12" no-caps></u-btn>
          </div>
          <div>
            <div class="fin-totais">
              <div class="m-b font-13">Total de <strong>{{table.serverPagination.rowsNumber}}</strong> lançamentos no período <span v-if="table.filters.data1 && table.filters.data2" class="font-bold">{{ convert(table.filters.data1)|formatDate('dd MMM yyyy') }} - {{ convert(table.filters.data2)|formatDate('dd MMM yyyy') }}</span></div>
              <div class="flex no-wrap justify-end">
                <div v-if="listType === 'r' || listType === 'e'">
                  <div class="dline">
                    <div class="lbl">Recebidas (R$)</div>
                    <div class="value text-positive">{{ (counts && counts.receitasPagas)|moeda }}</div>
                  </div>
                  <div class="dline">
                    <div class="lbl">Descontos (R$)</div>
                    <div class="value">-{{ (counts && counts.receitasPagasDescontos)|moeda }}</div>
                  </div>
                  <div class="dline">
                    <div class="lbl">Juros/Multa (R$)</div>
                    <div class="value">+{{ (counts && counts.receitasPagasJurosMulta)|moeda }}</div>
                  </div>
                  <div class="dline">
                    <div class="lbl">A receber (R$)</div>
                    <div class="value text-positive">{{ (counts && (Number(counts.receitasNaoPagas)))|moeda }}</div>
                  </div>
                  <div class="dline">
                    <div class="lbl">Vencidas (R$)</div>
                    <div class="value text-positive font-bold">{{ (counts && counts.receitasVencidas)|moeda }}</div>
                  </div>
                  <div class="dline">
                    <div class="lbl">Total (R$)</div>
                    <div class="value font-bold">{{ (counts && counts.receita)|moeda }}</div>
                  </div>
                </div>
                <div v-if="listType === 'd' || listType === 'e'">
                  <div class="dline">
                    <div class="lbl">Pagas (R$)</div>
                    <div class="value text-negative">-{{ (counts && counts.despesasPagas)|moeda }}</div>
                  </div>
                  <div class="dline">
                    <div class="lbl">Descontos (R$)</div>
                    <div class="value">-{{ (counts && counts.despesasPagasDescontos)|moeda }}</div>
                  </div>
                  <div class="dline">
                    <div class="lbl">Juros/Multa (R$)</div>
                    <div class="value">-{{ (counts && counts.despesasPagasJurosMulta)|moeda }}</div>
                  </div>
                  <div class="dline">
                    <div class="lbl">A pagar (R$)</div>
                    <div class="value text-negative">{{ (counts && (Number(counts.despesasNaoPagas)))|moeda }}</div>
                  </div>
                  <div class="dline">
                    <div class="lbl">Vencidas (R$)</div>
                    <div class="value text-negative font-bold">{{ (counts && counts.despesasVencidas)|moeda }}</div>
                  </div>
                  <div class="dline">
                    <div class="lbl">Total (R$)</div>
                    <div class="value font-bold">-{{ (counts && counts.despesa)|moeda }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-view />
    </div>
</template>
